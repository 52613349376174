.slick-next:before,
.slick-prev:before {
  font-size: 42px;
}

.slick-prev {
  left: -42px;
}

@media screen and (max-width: 1500px) {
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: 0;
  }
}
